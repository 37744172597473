import { LazyObserver } from "cadfem-modules/src/classes";
import opts from '../settings/mainSliderOpts';
const selector = '[jumbotronMainSlider]';


(new LazyObserver({
    selector: selector,
    activeClass:'data-jumbotronMainSlider-lazy',
    observe: ({target, IO, activeClass}) => {
      import('swiper/js/swiper.min')
        .then( module => new module.default(selector, opts(selector)) )
        .then( () => target.removeAttribute(activeClass) )
        .catch( error => console.error(error) );
      IO.unobserve(target);
    }
  })
).init();
