// lazy
import './lazy/mainSlider.m';
import './lazy/img';
import './lazy/bg';
import './lazy/styles.m';

// import modules
import './modules/cadfem';
import './modules/bootstrap';

// images
import './assets';

// require all svg in svg folder
import('./additional/svgRequire');

function autoPlayYouTubeModal() { // TODO: make through bootstrap modal native methods
  const trigger = $('body').find('[data-yt]');
  trigger.click(function triggerCb() {
    const theModal = $(this).data('target');
    const videoSRC = $(this).attr('data-video-url');
    const videoSRCauto = `${videoSRC}?autoplay=1&rel=0`;
    $(`${theModal} iframe`).attr('src', videoSRCauto);
    $(theModal).on('hidden.bs.modal', () => {
      $(`${theModal} iframe`).attr('src', videoSRC);
    });
  });
}
autoPlayYouTubeModal();

/*import Cookie from 'js-cookie'
if (!Cookie.get('CookieAccept')) {
  import('./cookiePopup');
}*/
